<script lang="ts" setup>
import { useVModels } from '@vueuse/core'

const props = defineProps(['value','isAutoCancel'])
const emits = defineEmits()
const { value, isAutoCancel } = useVModels(props, emits)


const cancelTimeOptions = ref([
  { value: 1800, key: '30m', label: $t('4Aj3npClzqNab5AeuuF0A') },
  { value: 3600, key: '1h', label: $t('qscwa0AArZFkKuiKRR4Gi') },
  { value: 10800, key: '3h', label: $t('4cPcjYm3cBE_F5c5zbBZ4') },
])
</script>

<template>
  <div class="text-primary">
    <ACheckbox v-model:checked="isAutoCancel" />
    <I18nT keypath="h3Ek4FbetwagbAzhROfRj" tag="span" class="mx-1">
      <template #select>
        <span class="mx-2">
          <ASelect
            v-model:value="value"
            :disabled="!isAutoCancel"
            :options="cancelTimeOptions"
            class="w-30"
          /></span>
      </template>
    </I18nT>
  </div>
</template>

<style scoped lang="scss">

</style>
